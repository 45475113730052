<script setup>
import { RouterLink, RouterView } from "vue-router";
</script>

<template>
    <div class="bg-[#5287FF]/25 backdrop-blur-[20px] md:w-full z-10 flex">
        <div class="max-w-screen-xl px-4 py-6 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
            <div class="flex max-[768px]:flex-col flex-wrap justify-center mx-5 my-2 font-bold">
                <div class="px-5 py-2 max-[768px]:text-center">
                    <RouterLink to="/mentionslegales" aria-label="Se rendre sur la page des mentions légales du site d'Elya Darmor" class="text-base leading-6 text-[#DB5E1D] hover:text-yellow-300">
                        Mentions légales</RouterLink>
                </div>
                <div class="px-5 py-2 max-[768px]:text-center">
                    <RouterLink to="/cgu" aria-label="Se rendre sur la page des conditions générales du site d'Elya Darmor" class="text-base leading-6 text-[#DB5E1D] hover:text-yellow-300">CGU</RouterLink>
                </div>
                <div class="px-5 py-2 max-[768px]:text-center">
                    <RouterLink to="/politiquedeconfidentialite" aria-label="Se rendre sur la page de politique de confidentialité du site d'Elya Darmor"
                        class="text-base leading-6 text-[#DB5E1D] hover:text-yellow-300">Politique de confidentialité
                    </RouterLink>
                </div>
            </div>
            <div class="flex justify-center mt-8 space-x-6">
                <a target="_blank" aria-label="Se rendre sur le TikTok d'Elya Darmor" :href="urlTiktok" class="text-[#DB5E1D] hover:text-yellow-300">
                    <span class="sr-only">TikTok</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class='w-6 h-6' viewBox="0 0 448 512">
                        <path fill="currentColor"
                            d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                    </svg>
                </a>
                <a target="_blank" aria-label="Se rendre sur la page Instagram d'Elya Darmor" :href="urlInstagram" class="text-[#DB5E1D] hover:text-yellow-300">
                    <span class="sr-only">Instagram</span>
                    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd"
                            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            clip-rule="evenodd"></path>
                    </svg>
                </a>
                <a target="_blank" aria-label="Se rendre sur la page Facebook du site d'Elya Darmor" :href="urlFacebook" class="text-[#DB5E1D] hover:text-yellow-300">
                    <span class="sr-only">Facebook</span>
                    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd"
                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                            clip-rule="evenodd"></path>
                    </svg>
                </a>
                <a target="_blank" aria-label="Se rendre sur la chaîne Youtube d'Elya Darmor" :href="urlYoutube" class="text-[#DB5E1D] hover:text-yellow-300">
                    <span class="sr-only">Youtube</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class='h-6 w-6' viewBox="0 0 576 512">
                        <path fill="currentColor"
                            d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
                    </svg>
                </a>
                <a target="_blank" aria-label="Se rendre sur le compte Spotify d'Elya Darmor" :href="urlSpotify" class="text-[#DB5E1D] hover:text-yellow-300">
                    <span class="sr-only">Spotify</span>
                    <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                        <path fill="currentColor"
                            d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm100.7 364.9c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4zm26.9-65.6c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm31-76.2c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3z" />
                    </svg>
                </a>
            </div>
            <p tabindex="0" class="mt-8 text-base leading-6 text-center text-[#DB5E1D] font-bold">
                © 2024 Elya Darmor
            </p>
        </div>
    </div></template>
<script>
export default {
    name: 'footerComponent',
    data(){
    return{
        urlFacebook: 'https://www.facebook.com/elya.darmor',
        urlInstagram: 'https://www.instagram.com/elya_darmor/',
        urlYoutube: 'https://www.youtube.com/channel/UCS8H788AeF-i_lXpcVBnD3w',
        urlTiktok: 'https://www.tiktok.com/@elya.darmor',
        urlSpotify: 'https://open.spotify.com/intl-fr/artist/0MkZSS77QcIBPxQLqTZ52y',
    }
  }
}
</script>

<style></style>