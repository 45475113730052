<template>
    <div class="mainContainer h-full flex flex-col">
        <Navbar />
        <RouterView />
    <div tabindex="1" class="btn flex justify-center z-20 fixed cursor-pointer right-6 bottom-5 items-center h-16 w-16 bg-orange-600 hover:bg-orange-800 rounded-[50%]" @click="scrollToTop">
      <img class="iconeFleche w-6" src="@/assets/arrow-up.svg" alt="icône de la flèche permettant de se renre en haut des pages">
    </div>
    </div>
    <footer class="w-full">
        <footerComponent />
    </footer>
</template>
<script setup>
import { VueDraggableNext } from 'vue-draggable-next'
import Navbar from './components/Navbar.vue';
import footerComponent from './components/footerComponent.vue';
import { RouterLink, RouterView } from "vue-router";
import axios from "axios";
import WaveSurfer from 'wavesurfer.js';
const scrollToTop = () => {
    window.scrollTo({
        top:0,
        left:0,
        behavior:'smooth',
    })
}
</script>
<style>
@font-face {
    font-family: 'Malgun Gothic';
    src: url('@/assets/fonts/Malgun_Gothic_Regular.ttf');
}

@font-face {
    font-family: 'Tw Cen MT Std';
    src: url('@/assets/fonts/TwCenMTStd.otf');
}

</style>
