<script setup>
import { RouterLink } from "vue-router";
import { ref, watch } from "vue";
import { useStore, mapMutations } from "vuex";
const store = useStore();
const menuOpen = ref(false);
const urlTiktok = "https://www.tiktok.com/@elya.darmor";
const urlInstagram = "https://www.instagram.com/elya_darmor/";
const urlFacebook = "https://www.facebook.com/elya.darmor";
const urlYoutube = "https://www.youtube.com/channel/UCS8H788AeF-i_lXpcVBnD3w";
const urlSpotify = "https://open.spotify.com/intl-fr/artist/0MkZSS77QcIBPxQLqTZ52y";
const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
};
store.state.selectedLanguage = "?locale=french";
</script>
<template>
  <nav class="sticky top-0 bg-[#5287FF]/25 backdrop-blur-[20px] z-10">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-16 items-center justify-between">
        <!-- Mobile menu button -->
        <div class="absolute inset-y-0 right-1 flex items-center xl:hidden">
          <button type="button" @click="toggleMenu"
            class="inline-flex items-center justify-center rounded-md p-2 text-[#DB5E1D] hover:bg-blue-900  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#DB5E1D]"
            aria-controls="mobile-menu" :aria-expanded="menuOpen">
            <span class="sr-only">Open main menu</span>
            <svg v-if="!menuOpen" class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
              stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <svg v-if="menuOpen" class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
              stroke-width="1.5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <!-- Logo -->
        <div class="flex flex-1 items-center justify-center xl:items-stretch xl:justify-start">
          <RouterLink to="/" @click="toggleMenu">
            <img loading="lazy" class="h-9 w-auto"
              src="https://backend.elyadarmor.com/assets/link/6647bf8907e5e7804f083460"
              alt="Logo du site d'Elya Darmor" />
          </RouterLink>
        </div>

        <!-- Desktop Menu -->
        <div class="hidden xl:flex xl:space-x-4">
          <RouterLink to="/quisuisje"
            class="text-[#DB5E1D] hover:text-yellow-300 rounded-md px-3 py-2 text-base font-bold">Qui suis-je ?
          </RouterLink>
          <RouterLink to="/actualites"
            class="text-[#DB5E1D] hover:text-yellow-300 rounded-md px-3 py-2 text-base font-bold">Actualités</RouterLink>
          <RouterLink to="/discographie"
            class="text-[#DB5E1D] hover:text-yellow-300 rounded-md px-3 py-2 text-base font-bold">Discographie
          </RouterLink>
          <RouterLink to="/galerie"
            class="text-[#DB5E1D] hover:text-yellow-300 rounded-md px-3 py-2 text-base font-bold">Galerie</RouterLink>
        </div>

        <!-- Language and Social Media Links -->
        <div class="hidden xl:flex xl:items-center xl:space-x-4">
          <div>
            <label for="languageSelect" class="sr-only">Sélectionnez votre langue</label>
            <select id="languageSelect" class="bg-transparent w-12 text-orange-600 font-bold"
              aria-label="Sélection de la langue" @change="updateLanguage">
              <option value="?locale=french">FR - Français</option>
              <option value="?locale=english">EN - English</option>
            </select>
          </div>
          <a :href="urlTiktok" target="_blank" aria-label="TikTok" class="text-[#DB5E1D] hover:text-yellow-300">
            <svg xmlns="http://www.w3.org/2000/svg" class='w-6 h-6' viewBox="0 0 448 512">
                  <path fill="#db5e1d"
                    d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
            </svg>
          </a>
          <a :href="urlInstagram" target="_blank" aria-label="Instagram" class="text-[#DB5E1D] hover:text-yellow-300">
            <svg xmlns="http://www.w3.org/2000/svg" class='w-6 h-6' viewBox="0 0 448 512">
                  <path fill="#db5e1d"
                    d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z" />
            </svg>
          </a>
          <a :href="urlFacebook" target="_blank" aria-label="Facebook" class="text-[#DB5E1D] hover:text-yellow-300">
            <svg xmlns="http://www.w3.org/2000/svg" class='w-6 h-6' viewBox="0 0 448 512">
                  <path fill="#db5e1d"
                    d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
                </svg>
          </a>
          <a :href="urlYoutube" target="_blank" aria-label="YouTube" class="text-[#DB5E1D] hover:text-yellow-300">
            <svg xmlns="http://www.w3.org/2000/svg" class='w-6 h-6' viewBox="0 0 576 512">
                  <path fill="#db5e1d"
                    d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
                </svg>
          </a>
          <a :href="urlSpotify" target="_blank" aria-label="Spotify" class="text-[#DB5E1D] hover:text-yellow-300">
            <svg xmlns="http://www.w3.org/2000/svg" class='w-6 h-6' viewBox="0 0 496 512">
                  <path fill="#db5e1d"
                    d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm100.7 364.9c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4zm26.9-65.6c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm31-76.2c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3z" />
            </svg>
          </a>
        </div>
      </div>
    </div>

    <!-- Mobile Menu -->
    <div v-show="menuOpen" class="xl:hidden" id="mobile-menu">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <RouterLink to="/quisuisje" @click="toggleMenu"
          class="text-[#DB5E1D] hover:text-yellow-300 block rounded-md px-3 py-2 text-base font-bold text-center">Qui suis-je
          ?</RouterLink>
        <RouterLink to="/actualites" @click="toggleMenu"
          class="text-[#DB5E1D] hover:text-yellow-300 block rounded-md px-3 py-2 text-base font-bold text-center">Actualités
        </RouterLink>
        <RouterLink to="/discographie" @click="toggleMenu"
          class="text-[#DB5E1D] hover:text-yellow-300 block rounded-md px-3 py-2 text-base font-bold text-center">
          Discographie</RouterLink>
        <RouterLink to="/galerie" @click="toggleMenu"
          class="text-[#DB5E1D] hover:text-yellow-300 block rounded-md px-3 py-2 text-base font-bold text-center">Galerie
        </RouterLink>
        <!-- Réseaux sociaux en mobile -->
        <div class="flex justify-center space-x-8 pt-4 pb-4">
          <a :href="urlTiktok" target="_blank" aria-label="TikTok" class="text-[#DB5E1D] hover:text-yellow-300">
            <svg xmlns="http://www.w3.org/2000/svg" class='h-[25px]' viewBox="0 0 448 512">
              <path fill="#db5e1d"
                d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
            </svg>
          </a>
          <a :href="urlInstagram" target="_blank" aria-label="Instagram" class="text-[#DB5E1D] hover:text-yellow-300">
            <svg xmlns="http://www.w3.org/2000/svg" class='h-[28px]' viewBox="0 0 448 512">
              <path fill="#db5e1d"
                d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z" />
            </svg>
          </a>
          <a :href="urlFacebook" target="_blank" aria-label="Facebook" class="text-[#DB5E1D] hover:text-yellow-300">
            <svg xmlns="http://www.w3.org/2000/svg" class='h-[28px]' viewBox="0 0 448 512">
              <path fill="#db5e1d"
                d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z" />
            </svg>
          </a>
          <a :href="urlYoutube" target="_blank" aria-label="YouTube" class="text-[#DB5E1D] hover:text-yellow-300">
            <svg xmlns="http://www.w3.org/2000/svg" class='h-[30px]' viewBox="0 0 576 512">
              <path fill="#db5e1d"
                d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z" />
            </svg>
          </a>
          <a :href="urlSpotify" target="_blank" aria-label="Spotify" class="text-[#DB5E1D] hover:text-yellow-300">
            <svg xmlns="http://www.w3.org/2000/svg" class='h-[27px]' viewBox="0 0 496 512">
              <path fill="#db5e1d"
                d="M248 8C111.1 8 0 119.1 0 256s111.1 248 248 248 248-111.1 248-248S384.9 8 248 8zm100.7 364.9c-4.2 0-6.8-1.3-10.7-3.6-62.4-37.6-135-39.2-206.7-24.5-3.9 1-9 2.6-11.9 2.6-9.7 0-15.8-7.7-15.8-15.8 0-10.3 6.1-15.2 13.6-16.8 81.9-18.1 165.6-16.5 237 26.2 6.1 3.9 9.7 7.4 9.7 16.5s-7.1 15.4-15.2 15.4zm26.9-65.6c-5.2 0-8.7-2.3-12.3-4.2-62.5-37-155.7-51.9-238.6-29.4-4.8 1.3-7.4 2.6-11.9 2.6-10.7 0-19.4-8.7-19.4-19.4s5.2-17.8 15.5-20.7c27.8-7.8 56.2-13.6 97.8-13.6 64.9 0 127.6 16.1 177 45.5 8.1 4.8 11.3 11 11.3 19.7-.1 10.8-8.5 19.5-19.4 19.5zm31-76.2c-5.2 0-8.4-1.3-12.9-3.9-71.2-42.5-198.5-52.7-280.9-29.7-3.6 1-8.1 2.6-12.9 2.6-13.2 0-23.3-10.3-23.3-23.6 0-13.6 8.4-21.3 17.4-23.9 35.2-10.3 74.6-15.2 117.5-15.2 73 0 149.5 15.2 205.4 47.8 7.8 4.5 12.9 10.7 12.9 22.6 0 13.6-11 23.3-23.2 23.3z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>