import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Accueil',
    meta : {
      title: 'Accueil - Elya Darmor',
      keywords:'Elya, Elya Darmor, Chanteuse, Compositrice, Musique, Pop, Française, Artiste, Artiste française, Artiste pop, Artiste pop française, Artiste française pop, Artiste française populaire, Artiste populaire, Artiste populaire française, Artiste populaire française pop, Jazz,Jazzpop',
      description: 'Découvrez l\'univers d\'Elya Darmor, chanteuse et compositrice française. Retrouvez ses actualités, sa discographie, sa galerie et bien plus encore !',
      robots: 'index,follow',
    },
    component: () => import('../views/AccueilView.vue')
  },
  {
    path: '/actualites',
    name: 'Actualites',
    meta : {
      title: 'Actualités - Elya Darmor',
      keywords:' Concerts, Albums, Singles, Radio, Télévision, Télé, Projets, Actualités, News, Newsletters, Newsletters, Elya Darmor, Chanteuse, Compositrice, Musique, Pop, Française, Artiste, Artiste française, Artiste pop, Artiste pop française, Artiste française pop, Artiste française populaire, Artiste populaire, Artiste populaire française, Artiste populaire française pop, Jazz,Jazzpop',
      description: 'Retrouvez toutes les actualités d\'Elya Darmor, chanteuse et compositrice française. Découvrez ses derniers projets, ses concerts et bien plus encore !',
      robots: 'index, follow',
    },
    component: () => import('../views/ActualitesView.vue')
  },
{
  path: '/discographie',
  name: 'Discographie',
  meta : {
    title: 'Discographie - Elya Darmor',
    keywords:'Discographie, Star d\'un soir, Qu\'est-ce qu\'on est bien, Passagers, La roue tourne, Cet effet-là, EP, Albums, Singles, Collaborations, Elya Darmor, Chanteuse, Compositrice, Musique, Pop, Française, Artiste, Artiste française, Artiste pop, Artiste pop française, Artiste française pop, Artiste française populaire, Artiste populaire, Artiste populaire française, Artiste populaire française pop, Jazz,Jazzpop',
    description: 'Découvrez la discographie d\'Elya Darmor, chanteuse et compositrice française. Retrouvez tous ses albums, singles et collaborations !',
    robots:'index,follow',
  },
  component: () => import('../views/DiscographieView.vue')
},
{
  path: '/galerie',
  name: 'Galerie',
  meta : {
    title: 'Galerie - Elya Darmor',
    keywords: ' Galerie, Photos, Vidéos, Créations, Elya, Dossier de presse, Presse, Elya Darmor, Chanteuse, Compositrice, Musique, Pop, Française, Artiste, Artiste française, Artiste pop, Artiste pop française, Artiste française pop, Artiste française populaire, Artiste populaire, Artiste populaire française, Artiste populaire française pop, Jazz,Jazzpop', 
    description: 'Découvrez la galerie d\'Elya Darmor, chanteuse et compositrice française. Retrouvez toutes ses photos, ses vidéos et ses créations !',
    robots: 'index, follow', 
  },
  component: () => import('../views/GalerieView.vue')
},
{
  path: '/quisuisje',
  name: 'Qui suis-je ?',
  meta : {
    title: 'Qui suis-je ? - Elya Darmor',
    keywords : ' Biographie, Parcours, Tournai, Wallonie, Belgique, Influences, Elya Darmor, Chanteuse, Compositrice, Musique, Pop, Française, Artiste, Artiste française, Artiste pop, Artiste pop française, Artiste française pop, Artiste française populaire, Artiste populaire, Artiste populaire française, Artiste populaire française pop, Jazz,Jazzpop',
    description: 'Découvrez qui est Elya Darmor, chanteuse et compositrice française. Retrouvez sa biographie, ses influences et son parcours !',
    robots : 'index,follow',
  },
  component: () => import('../views/QuisuisjeView.vue')
},
{
  path: '/politiquedeconfidentialite',
  name: 'Politique de confidentialité',
  meta : {
    title: 'Politique de confidentialité - Elya Darmor',
    keywords : 'Politique de confidentialité, Confidentialité, Privacy, Privacy policy', 
    description: 'Consultez la politique de confidentialité d\'Elya Darmor, chanteuse et compositrice française. Retrouvez toutes les informations relatives à la collecte et au traitement de vos données personnelles.',
    robots : 'index,follow',
  },
  component: () => import('../views/PolitiquedeconfidentialiteView.vue')
},
{
  path: '/cgu',
  name: 'CGU',
  meta :{
    title : 'Conditions générales d\'utilisation - Elya Darmor',
    keywords : 'Conditions générales d\'utilisation, CGU, Utilisation, Utilisation du site, Utilisation des données, Utilisation des données personnelles, Elya Darmor, Chanteuse, Compositrice, Musique, Pop, Française, Artiste, Artiste française, Artiste pop, Artiste pop française, Artiste française pop, Artiste française populaire, Artiste populaire, Artiste populaire française, Artiste populaire française pop, Jazz,Jazzpop',
    description : 'Consultez les conditions générales d\'utilisation d\'Elya Darmor, chanteuse et compositrice française. Retrouvez toutes les informations relatives à l\'utilisation du site et à la protection des données personnelles.',
    robots : 'index, follow',
  },
  component: () => import('../views/CGUView.vue')
},
{
  path: '/mentionslegales',
  name: 'Mentions légales',
  meta : {
    title: 'Mentions légales - Elya Darmor',
    keywords : 'Mentions légales, Mentions, Légales, Mentions légales du site, Mentions légales du site web, Mentions légales du site internet, Elya Darmor, Chanteuse, Compositrice, Musique, Pop, Française, Artiste, Artiste française, Artiste pop, Artiste pop française, Artiste française pop, Artiste française populaire, Artiste populaire, Artiste populaire française, Artiste populaire française pop, Jazz,Jazzpop',
    description: 'Consultez les mentions légales d\'Elya Darmor, chanteuse et compositrice française. Retrouvez toutes les informations relatives à l\'éditeur du site, à l\'hébergeur et aux droits d\'auteur.',
    robots : 'index,follow',
  },
  component: () => import('../views/MentionslegalesView.vue')
},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  document.title = to.meta?.title ?? 'Pas de titre défini';
  document.querySelector('meta[name="description"]').setAttribute('content', to.meta?.description ?? 'Pas de description définie');
  document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta?.keywords ?? 'Aucun mot clef n\'a été défini pour cette page');
  document.querySelector('meta[name="robots"]').setAttribute('content', to.meta?.robots ?? 'noindex,noffolow');
}
)
export default router
