import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createStore } from 'vuex'
import './assets/tailwind.css'
const store = createStore({
    state(){
        return{
            selectedLanguage: '',
        }
    },
    mutations: {
        setSelectedLanguage(state, language){
            state.selectedLanguage = language;
        },
    },
});
function obscureToken(token) {
    return btoa(token);
}
const token = 'API-c98cfb7c91e1fdc8fbc8478f38f622f55844e6ee';
const obscuredToken = obscureToken(token);
localStorage.setItem('token', obscuredToken);
createApp(App).use(store).use(router).mount('#app')
